<template>
    <modal :styles="'max-w-7xl w-7xl'">
        <h3 class="text-black font-bold text-xl mb-6">
            Документ от {{ doc.created_at | datetime }}
        </h3>

        <div class="flex flex-row divide-x">
            <div class="w-1/2 pr-4">
                <div class="flex flex-row space-x-2 justify-start items-center">
                    <button @click="switchImage(index)" v-for="img, index in doc.images"
                        class="relative rounded-md overflow-hidden">
                        <img :src="img.src" class="w-10 h-10 aspect-square object-cover" />
                        <div class="absolute top-0 left-0 text-center w-full h-full bg-black"
                            :class="selectedImageIndex === index ? 'bg-opacity-10 text-white' : 'bg-opacity-50 text-gray-300'">
                            <span class="text-xs">{{ index }}</span>
                        </div>
                    </button>
                </div>
                <div class="relative mt-2 overflow-hidden" id="container">
                    <img :src="doc.images[selectedImageIndex].src" id="zoomable-image" v-if="doc.images" />
                </div>
            </div>
            <div class="w-1/2 pl-4 pb-6">
                <div class="text-right mb-2" v-if="doc.results && doc.results.length > 0">
                    <button class="text-xs btn-link" @click="downloadJSON()">
                        <i class="fas fa-download"></i> Скачать JSON
                    </button>
                </div>
                <div class="px-8 rounded-md bg-gray-100  h-full py-4 shadow-inner">
                    <div class="text-gray-800 font-mono text-xs " v-if="doc.results && doc.results.length > 0">

                        <pre class="whitespace-pre-wrap">
                        {{ renderResults() }}
                    </pre>
                    </div>
                </div>
            </div>
        </div>


    </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";

export default {
    components: { Modal },
    mixins: [ModalMixin],
    props: ["id"],
    data() {
        return {
            loading: false,
            doc: {},
            selectedImageIndex: 0
        };
    },
    mounted() {
        if (!this.id) {
            alert('no id');
        } else {
            this.getDocument();
        }

    },
    methods: {
        async getDocument() {
            let { success, msg, data } = await api.get(`/documents/${this.id}`);
            if (success) {
                this.doc = data;
                setTimeout(() => {
                    this.activateZoom();
                }, 100);
            }
        },
        getLink(img) {
            return
        },
        switchImage(index) {
            this.selectedImageIndex = index
            this.activateZoom();
        },
        activateZoom() {

            const img = document.getElementById('zoomable-image');
            const container = document.getElementById('container');
            img.style.transformOrigin = `0 0`;
            img.style.transform = `translate(0%, 0%) scale(1)`;

            let scale = 1;
            // drag
            // Variables to track drag state
            let isDragging = false;
            let lastX = 0;
            let lastY = 0;
            let posX = 0; // Initial position (50% to center the image)
            let posY = 0;

            // Function to handle scaling on mouse wheel
            function handleWheel(event) {
                event.preventDefault(); // Prevent page scroll

                // Get the cursor position relative to the image
                const rect = img.getBoundingClientRect();
                const cursorX = event.clientX - rect.left; // X position inside the image
                const cursorY = event.clientY - rect.top;  // Y position inside the image

                // Determine the scale factor based on the scroll direction
                if (event.deltaY < 0) {
                    scale += 0.1; // Scroll up: scale up
                } else if (event.deltaY > 0) {
                    scale -= 0.1; // Scroll down: scale down
                }

                // Limit the scale to a reasonable range
                scale = Math.max(1, Math.min(scale, 5)); // Min scale 0.1, max scale 5

                // Set the transform origin to the cursor position inside the image
                img.style.transformOrigin = `${(cursorX / rect.width) * 100}% ${(cursorY / rect.height) * 100}%`;
                img.style.transform = `translate(${posX}%, ${posY}%) scale(${scale})`;
            }

            // Function to handle the start of dragging
            function startDrag(event) {
                event.preventDefault(); // Prevent default browser drag behavior
                isDragging = true;
                lastX = event.clientX;
                lastY = event.clientY;
            }

            // Function to handle dragging movement
            function drag(event) {
                if (isDragging) {
                    const dx = event.clientX - lastX;
                    const dy = event.clientY - lastY;

                    // Update position based on drag movement, taking scale into account
                    posX += (dx / (img.offsetWidth * scale)) * 100;
                    posY += (dy / (img.offsetHeight * scale)) * 100;

                    // Apply new position and scale
                    img.style.transform = `translate(${posX}%, ${posY}%) scale(${scale})`;

                    // Update last mouse position
                    lastX = event.clientX;
                    lastY = event.clientY;
                }
            }

            // Function to stop dragging
            function stopDrag() {
                isDragging = false;
            }

            // Listen for the mouse wheel event only when the cursor is over the image
            img.addEventListener('wheel', handleWheel);

            // Listen for drag events
            img.addEventListener('mousedown', startDrag);
            window.addEventListener('mousemove', drag);
            window.addEventListener('mouseup', stopDrag);

            // Prevent default drag behavior on the image
            img.addEventListener('dragstart', (event) => event.preventDefault());
        },
        renderResults() {
            let item = this.doc.results[this.selectedImageIndex]
            if (item) {
                return JSON.stringify(item, null, 4);
            }
        },
        // generate file with json inside and trigger download
        downloadJSON() {
            let item = this.doc.results[this.selectedImageIndex]
            if (item) {
                let blob = new Blob([JSON.stringify(item, null, 4)], {
                    type: "application/json;charset=utf-8"
                });

                let url = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = `${this.doc.number} - ${item.type ?? ''}.json`;
                document.body.appendChild(a);
                a.click();
            }
        }
    }
};
</script>

<style scope>
#zoomable-image {
    cursor: grab;
}

#zoomable-image:active {
    cursor: grabbing;
}
</style>