<template>
    <page>
        <template v-slot:header>
            Документы
        </template>
        <template v-slot:actions>
            <button @click="startCamera" class="btn-action-xs ml-4">
                <i class="fas fa-plus mr-1"></i>
                Сканировать документ
            </button>
        </template>

        <!-- Filters, collapsable -->
        <template v-slot:content>

            <div>
                <pagination :total="filter.total" :limit="filter.limit" :items="items" :name="'supply'"
                    @limit="limit => ((filter.limit = limit), getItems())"
                    @page="page => ((filter.page = page), getItems())" />

                <div class="w-full overflow-x-auto text-left bg-white rounded-md">
                    <table class="w-full data-table">
                        <thead>
                            <tr class="font-semibold text-xs  text-gray-600">
                                <th>Номер</th>
                                <th class="">Дата</th>
                                <th class="">Статус</th>
                                <th class="">Название</th>
                                <th class="">Кто добавил</th>
                                <th class="w-10">Заметка</th>
                            </tr>
                        </thead>
                        <tbody class="text-sm">
                            <tr v-for="item in items" :key="item.id">
                                <td>
                                    <button @click="openDoc(item)" class="btn-link">
                                        {{ item.number }}
                                    </button>
                                </td>
                                <td>
                                    <button @click="openDoc(item)" class="btn-link">
                                        {{ item.created_at | datetime }} [{{ item.files.length }}]
                                    </button>
                                </td>
                                <td>
                                    <task-status :status="item.status" />
                                    <span v-if="item.status == 'error'">
                                        {{ item.error }}
                                    </span>
                                </td>
                                <td>
                                    <div v-for="type in getDocumentTypes(item)">- {{ type }}</div>
                                </td>
                                <td><user-card :id="item.user_id" /></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="error" class="text-red-500 font-semibold py-2">{{ error }}</div>
                <loading v-if="busy" />

            </div>

            <!-- scan doc -->

            <div class="fixed ioswrapperfix inset-0 w-screen h-screen bg-black z-20 bg-opacity-50 flex flex-col justify-center items-center"
                v-if="showScanner">
                <!-- draw a simple boundary box -->
                <!-- draw buttons Scan & Next -->

                <div class="camera-container flex flex-row items-center justify-center">
                    <button class="text-white text-2xl absolute top-4 right-8 z-30" @click="stopCamera()">
                        <i class="fas fa-times"></i>
                    </button>

                    <loading class="z-1" />
                    <video ref="video" class="camera-view hidden" playsinline></video>
                    <canvas ref="canvas" class="canvas-overlay z-10"></canvas>
                    <canvas ref="actualCanvas" class="absolute left-0 border hidden"></canvas>

                    <!-- <div class="boundary-box"></div> -->
                    <div v-if="scannedImages.length > 0" class="z-30 absolute top-6 left-6 ">
                        <div
                            class="grid grid-cols-5   sm:grid-cols-10 md:grid-cols-10 whitespace-normal rounded-md gap-2 justify-center w-full bg-opacity-50 bg-white py-1 px-1">
                            <div v-for="(image, index) in scannedImages" :key="index">
                                <img :src="image" :alt="'Scanned Image ' + (index + 1)" class="w-10"
                                    @click="removeImage(index)" />
                            </div>
                        </div>
                        <div class="rounded-md text-xs bg-blue-500 text-white px-2 py-1 mt-1 bg-opacity-60">
                            Чтобы удалить изображение, нажмите на него.
                        </div>
                    </div>

                </div>

                <button class="btn-action-sm mr-2 scan-btn absolute z-30" @click="captureImage"
                    :class="{ busy: captureBusy }" :disabled="captureBusy">Сканировать</button>
                <button class="btn-primary-sm complete-btn absolute z-30" @click="saveDocument()"
                    v-if="scannedImages.length > 0">Завершить</button>

            </div>

        </template>

    </page>
</template>
<style scoped>
.camera-container {
    position: relative;
    height: 100%;
    width: auto;
    margin: 0 auto;
}

.camera-view {
    width: 100%;
    height: auto;
}

.boundary-box {
    position: absolute;
    top: 5%;
    left: 5%;
    /* width: 47.82%;
    height: 90%; */
    width: 90%;
    height: 85.11%;
    border: 4px solid green;
    box-sizing: border-box;
    pointer-events: none;
}

.canvas-overlay {
    /* position: absolute;
    top: 0;
    left: 0;
    z-index: 1; */
    /* max-width: 800px; */
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 1px solid red;
    /* height: 300px; */
}
</style>

<script>
import { mapGetters } from "vuex";
import Page from "../Page.vue";
import Info from "../shared/Info.vue";
import Loading from "../Loading.vue";
import api from "../../api/api.js";
import UserCard from "../shared/UserCard.vue";
import ViewDocModal from "./ViewDoc.vue";
let cv;

export default {
    components: { Page, Info, Loading, UserCard },
    name: "docs",
    data() {
        return {
            saveBusy: false,
            items: [],
            searchDate: "",
            busy: false,
            error: "",
            suppliers: [],
            filter: {
                page: 1,
                limit: 10,
                term: "",
                date: "",
                total: 0
            },
            scannedImages: [],
            showScanner: false,
            src: null,
            gray: null,
            blur: null,
            edges: null,
            contours: null,
            hierarchy: null,
            box: {},
            showSuccess: false,
            captureBusy: false,
            stream: null
        };
    },
    mounted() {
        let vm = this;
        //this.startCamera()
        this.getItems();
        // insert js script to the page
        let script = document.createElement("script");
        script.src = "/libs/opencv.js";
        document.head.appendChild(script);
        script.onload = function () {
            cv = window.cv;
        }

    },
    methods: {
        openDoc(doc) {
            this.$modals.open(ViewDocModal, {
                id: doc.id
            })
        },
        // get supply list
        async getItems() {
            this.busy = true;
            let query = encodeURIComponent(JSON.stringify(this.filter));
            let { success, msg, data } = await api.get(`/documents?filter=${query}`);
            if (success) {
                this.items = data.items;
                this.filter.total = data.total;
            }

            this.busy = false;
        },
        async startCamera() {

            this.scannedImages = []
            this.showScanner = true;
            let vm = this;

            if (!navigator.mediaDevices) {
                this.showScanner = false;
                alert('no media devices')
                return
            }

            if (navigator.mediaDevices) {

                const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

                try {
                    this.stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            width: { ideal: 1280 },
                            height: { ideal: 720 },
                            facingMode: { exact: "environment" }
                        }
                    });
                } catch (e) {
                    this.stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            width: { ideal: 1280 },
                            height: { ideal: 720 },
                        }
                    });
                }
                this.$refs.video.srcObject = this.stream;
                this.$refs.video.playsInline = true;

                this.$refs.video.addEventListener("loadedmetadata", function (e) {
                    console.log('loaded')
                    vm.detectA4Document();
                    vm.$refs.video.play();
                }, false);
            }

            this.scannedImages = [];
        },
        stopCamera() {
            this.showScanner = false;
            this.$refs.video.pause();
            this.stream.getTracks().forEach(function (track) {
                track.stop();
            });
        },
        detectA4Document() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const actualCanvas = this.$refs.actualCanvas;
            const actualCtx = actualCanvas.getContext('2d');

            let width = video.videoWidth
            let height = video.videoHeight

            // Initialize OpenCV matrices
            this.src = new cv.Mat(height, width, cv.CV_8UC4);
            this.gray = new cv.Mat(height, width, cv.CV_8UC1);
            this.blur = new cv.Mat(height, width, cv.CV_8UC1);
            this.edges = new cv.Mat(height, width, cv.CV_8UC1);
            this.contours = new cv.MatVector();
            this.hierarchy = new cv.Mat();

            video.addEventListener('play', () => {

                const processFrame = () => {

                    if (video.paused || video.ended) return;

                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                    // Convert ImageData to OpenCV Mat
                    this.src.data.set(imageData.data);

                    // Step 1: Convert to grayscale
                    cv.cvtColor(this.src, this.gray, cv.COLOR_RGBA2GRAY);

                    // Step 2: Apply Gaussian Blur
                    cv.GaussianBlur(this.gray, this.blur, new cv.Size(5, 5), 0);

                    // Step 3: Edge Detection (Canny)
                    cv.Canny(this.blur, this.edges, 75, 200);

                    // Step 4: Find contours and detect A4
                    cv.findContours(
                        this.edges,
                        this.contours,
                        this.hierarchy,
                        cv.RETR_TREE,
                        cv.CHAIN_APPROX_SIMPLE
                    );
                    cv.imshow(canvas, this.edges);

                    let rects = [];
                    for (let i = 0; i < this.contours.size(); ++i) {
                        let cnt = this.contours.get(i);
                        let approx = new cv.Mat();
                        cv.approxPolyDP(cnt, approx, 0.02 * cv.arcLength(cnt, true), true);

                        // Calculate the bounding box of the contour to get size and aspect ratio
                        const rect = cv.boundingRect(approx);
                        const width = rect.width;
                        const height = rect.height;

                        // Filter by size
                        if (width > 400 && height > 150) {
                            rects.push(rect);
                            console.log(rect)
                            this.box = rect;
                        }

                        approx.delete();
                    }

                    // Merge overlapping rectangles
                    rects = this.mergeOverlappingRectangles(rects);

                    if (rects.length > 0 && rects[0] != undefined) {

                        let rect = rects[0];
                        // generate green color

                        // Draw the boundary box around the contour

                        //always store biggest boundary box
                        // if (this.box.width < rect.width || !this.box.width) {
                        //     this.box = rect;
                        // }

                        // if (this.box.height < rect.height || !this.box.height) {
                        //     this.box = rect;
                        // }

                    } else {
                        this.box = {}
                    }

                    if (this.box.width && this.box.height) {
                        const color = new cv.Scalar(0, 255, 0, 255);
                        cv.rectangle(this.src, new cv.Point(this.box.x, this.box.y), new cv.Point(this.box.x + this.box.width, this.box.y + this.box.height), color, 2);
                    }

                    //const color = new cv.Scalar(0, 0, 255, 255);
                    //cv.rectangle(this.src, new cv.Point(this.box.x, this.box.y), new cv.Point(this.box.x + this.box.width, this.box.y + this.box.height), color, 2);

                    // Show the final result with detected contours
                    cv.imshow(canvas, this.src);

                    actualCanvas.width = this.box.width;
                    actualCanvas.height = this.box.height;
                    if (this.box.x) {

                        actualCtx.drawImage(
                            canvas,
                            this.box.x, this.box.y, this.box.width, this.box.height,
                            0, 0, this.box.width, this.box.height
                        );
                    }


                    requestAnimationFrame(processFrame);
                };

                processFrame();
            });
        },
        mergeOverlappingRectangles(rects) {
            // Sort rectangles by their x-coordinate
            rects.sort((a, b) => a.x - b.x);

            const mergedRects = [];

            let currentRect = rects[0];

            for (let i = 1; i < rects.length; i++) {
                const nextRect = rects[i];

                // Check if the rectangles overlap
                if (
                    currentRect.x < nextRect.x + nextRect.width &&
                    currentRect.x + currentRect.width > nextRect.x &&
                    currentRect.y < nextRect.y + nextRect.height &&
                    currentRect.y + currentRect.height > nextRect.y
                ) {
                    // Merge the rectangles
                    const mergedX = Math.min(currentRect.x, nextRect.x);
                    const mergedY = Math.min(currentRect.y, nextRect.y);
                    const mergedWidth = Math.max(currentRect.x + currentRect.width, nextRect.x + nextRect.width) - mergedX;
                    const mergedHeight = Math.max(currentRect.y + currentRect.height, nextRect.y + nextRect.height) - mergedY;

                    currentRect = { x: mergedX, y: mergedY, width: mergedWidth, height: mergedHeight };
                } else {
                    mergedRects.push(currentRect);
                    currentRect = nextRect;
                }
            }

            mergedRects.push(currentRect);

            return mergedRects;
        },
        captureImage() {
            let vm = this;
            this.captureBusy = true;
            // const canvas = document.createElement('canvas');
            // console.log(this.box)

            // const video = this.$refs.video;
            // canvas.width = this.box.width;
            // canvas.height = this.box.height;
            // const context = canvas.getContext('2d');
            // context.drawImage(video, this.box.x, this.box.y, this.box.width, this.box.height);
            // const image = canvas.toDataURL('image/jpeg', 1);
            // this.scannedImages.push(image);
            const video = this.$refs.video;
            const canvasSrc = this.$refs.canvas;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const actualCanvas = this.$refs.actualCanvas;
            const actualCtx = actualCanvas.getContext('2d');


            // Set the canvas size to match the region's size
            let { x, y, height, width } = this.box;

            // Set the canvas size to match the scaled region's size
            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(
                video,              // Source: video element
                x, y,
                width, height,
                0, 0, width, height
            );

            // Convert the canvas to a data URL (Base64 image string) or get the image data
            const dataURL = canvas.toDataURL('image/jpeg', 1);

            this.scannedImages.push(dataURL);

            setTimeout(() => {
                vm.captureBusy = false;
            }, 500);
        },
        showScannedImages() {
            // Optionally, you can add more logic to handle displaying or sending the images.
        },
        removeImage(index) {
            if (confirm('Удалить изображение?')) {
                this.scannedImages.splice(index, 1);
            }
        },
        async saveDocument() {

            if (!confirm('Вы хотите завершить сканирование и отправить отсканированный документ?')) {
                return;
            }

            this.$swal({
                icon: "info",
                title: "Загружаем документ...",
                text: 'Ожидайте пожалуйста'
            })

            this.saveBusy = true;
            try {
                const { success, msg, data } = await this.$api.post('/documents/upload', { images: this.scannedImages });
                // show success message
                if (success) {
                    this.$swal({
                        icon: "success",
                        title: "Успешно!",
                        text: 'Документ загружен, можно переходить к следующему'
                    })
                } else {
                    throw new Error(msg);
                }

            } catch (error) {
                console.error('Failed to upload images:', error);
                // show error message, ask to try again
                this.$swal({
                    icon: "error",
                    title: "Ошибка",
                    text: 'Не удалось загрузить документ, попробуйте ещё раз. ' + error
                })
            }

            this.saveBusy = false;
            this.showScanner = false;

            this.getItems();

        },
        getDocumentTypes(item) {
            if (item.results && item.results.length > 0) {
                return item.results.map(el => el.type)
            }
        }

    },
    computed: {
        ...mapGetters("users", ["getById"])
    }
};
</script>